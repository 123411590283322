<template>
  <!-- nav drawer -->
  <v-navigation-drawer
    app
    dark
    width="275"
    color="grey darken-3"
    v-if="$store.state.navDrawer"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">SAV Admin Portal</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list dense nav rounded>
      <!--nav links -->
      <v-list-item
        link
        exact
        :to="item.path"
        v-for="(item, index) in navLinks"
        :key="index"
      >
        <v-list-item-icon v-if="item.icon">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            >{{ item.text }}
            <v-badge
              v-if="item.newItemsBadge"
              dot
              class="ma-0 ml-2"
              inline
              color="pink"
            >
            </v-badge>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- nav links -->
      <!-- inventory links  -->
      <v-list-group prepend-icon="mdi-map-marker-outline">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Inventory</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          link
          exact
          v-for="(item, index) in inventoryLinks"
          :key="index"
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <!-- inventory links  -->
      <!-- package links  -->
      <v-list-group prepend-icon="mdi-view-grid-outline">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Packages</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          link
          exact
          v-for="(item, index) in packageLinks"
          :key="index"
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <!-- package links  -->
      <!--Alternative Destinations -->
      <v-list-item link exact to="/alternative-destinations">
        <v-list-item-icon>
          <v-icon>mdi mdi-package</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Alternative Destinations</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Alternative Destinations -->
      <!--admin -->
      <v-list-item link exact to="/admin">
        <v-list-item-icon>
          <v-icon>mdi-code-tags</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            >Admin
            <v-badge
              dot
              class="ma-0 ml-2"
              inline
              color="pink"
              v-if="adminNotifications && adminNotifications.length"
            ></v-badge>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- admin -->
      <!--mps -->
      <v-list-item link exact to="/mps">
        <v-list-item-icon>
          <v-icon>mdi-text-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>MPS </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!--Auctioneers -->
      <v-list-item link exact to="/auctioneers">
        <v-list-item-icon>
          <v-icon>mdi-bullhorn-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Auctioneers </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- credits -->
      <v-list-item link exact to="/credits">
        <v-list-item-icon>
          <v-icon>mdi-information-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Credits</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Standard Operating Procedures -->
      <v-list-item link exact to="/standardoperatingprocedures">
        <v-list-item-icon>
          <v-icon>mdi mdi-file-pdf-box</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Standard Operating Procedures</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Staff -->
      <v-list-item link exact to="/staff" v-if="auth">
        <v-list-item-icon>
          <v-icon>mdi mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Staff</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- credits -->
      <v-list-item link @click="rebuildMainSite">
        <v-list-item-icon>
          <v-icon>mdi-refresh</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Rebuild Main Site</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- footer -->
    <template v-slot:append>
      <div class="d-flex justify-end">
        <v-btn icon @click="signOut" class="mr-4 mb-2">
          <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
        <v-btn
          icon
          class="mr-4 mb-2"
          @click="$vuetify.theme.dark = !$vuetify.theme.dark"
        >
          <v-icon>{{
            $vuetify.theme.dark ? "mdi-lightbulb-outline" : "mdi-lightbulb"
          }}</v-icon>
        </v-btn>
      </div>
    </template>
    <v-dialog
      transition="dialog-top-transition"
      width="40%"
      v-model="rebuildDialog"
      v-if="rebuildDialog"
    >
      <v-card>
        <!-- <v-toolbar
              color="primary"
             
            >Opening from the top</v-toolbar> -->
        <!-- <v-card-title class="text-h5">
            Use Google's location service?
          </v-card-title> -->
        <v-toolbar
          dark
          color="primary"
          dense
          flat
          style="position: sticky; top: 0; width: 100%; z-index: 500"
        >
          <v-toolbar-title>
            <strong>Rebuild Main Site</strong>
            <span class="ml-6"> </span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              depressed
              color="primary"
              dark
              :loading="loading"
              :disabled="loading"
              @click="rebuildDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="text-h6 pa-8" v-if="!rebootStatus">
            <v-alert
              dense
              outlined
              type="error"
              v-if="buildMessage == 'Unsuccessful'"
            >
              Last build was unsuccessful
            </v-alert>
            Are you sure you want to reboot main site?
          </div>
          <div class="text-h6 pa-8" v-if="rebootStatus">
            Build in progress. Please wait.
          </div>
        </v-card-text>
        <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-darken-1" variant="text" @click="rebuildDialog = false">
              Disagree
            </v-btn>
            <v-btn color="blue-darken-1" variant="text" @click="rebuildAgree">
              Agree
            </v-btn>
          </v-card-actions> -->
        <v-card-actions class="sticky-v-card-actions">
          <v-spacer />
          <v-btn
            color="secondary"
            depressed
            :loading="loading"
            :disabled="loading || rebootStatus"
            @click="rebuildDialog = false"
            >No</v-btn
          >
          <v-btn
            color="primary"
            depressed
            :loading="loading"
            :disabled="loading || rebootStatus"
            @click="rebuildAgree"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- footer -->
  </v-navigation-drawer>
  <!-- nav drawer -->
</template>

<script>
import { portalsApp } from "@/firebase";
import signOut from "@/mixins/signOut";
import router from "@/router";
import axios from "axios";
export default {
  mixins: [signOut],
  firestore: {
    userAccounts: portalsApp.firestore().collection("userAccounts"),
    bookingsConfirmations: portalsApp
      .firestore()
      .collection("bookingsConfirmations")
      .where("isConfirmed", "==", false)
      .where("archived", "==", false),
    adminNotifications: portalsApp
      .firestore()
      .collection("adminNotifications")
      .where("archived", "==", false),
  },
  async mounted() {
    // fetching user accounts for "messages" nav item
    //  bind to user accounts
    // let userAccounts = await this.$bind(
    //   "userAccounts",
    //   portalsApp.firestore().collection("userAccounts")
    // );
    // if (userAccounts && !userAccounts.length) return;
    // map only data needed
    // userAccounts = userAccounts.map((i) => {
    //   return {
    //     id: i.id,
    //     timeline: i.timeline,
    //   };
    // });
    // reducing to unread messages => !readByAdmin
    // setting nav item badge
    // userAccounts.forEach((i) => {
    //   let unreadMessages = i.timeline.filter((i) => !i.readByAdmin);
    //   if (unreadMessages && unreadMessages.length) {
    //     let navItem = this.navLinks.find((i) => i.text == "Messages");
    //     if (navItem) navItem.newItemsBadge = true;
    //   }
    // });
  },
  data: () => ({
    rebootStatus: false,
    buildMessage: "",
    loading: false,
    rebuildDialog: false,
    adminNotifications: [],
    bookingsConfirmations: [],
    userAccounts: [],
    routes: [
      {
        path: "/staff",
        component: function () {
          return import("../views/Staff.vue");
        },
      },
    ],
    packageLinks: [
      {
        text: "New Package",
        path: "/packages/new",
      },
      {
        text: "All Packages",
        path: "/packages",
      },
      {
        text: "Categories",
        path: "/packages/categories",
      },
      {
        text: "Room Types",
        path: "/packages/roomtypes",
      },
      {
        text: "Number of Nights",
        path: "/packages/numbernights",
      },
      {
        text: "Terms & Conditions",
        path: "/packages/terms",
      },
    ],
    inventoryLinks: [
      {
        text: "Create Inventory",
        path: "/inventory/new",
      },
      {
        text: "All Inventory",
        path: "/inventory",
      },
      {
        text: "Traveler View",
        path: "/inventory/traveler-view",
      },
      {
        text: "Sitemaps",
        path: "/inventory/sitemaps",
      },
      {
        text: "Scrape",
        path: "/inventory/scrape",
      },
       {
        text: "Hotels.com Scrape",
        path: "/inventory/scrapelistings",
      },
      {
        text: "Upload",
        path: "/inventory/upload",
      },

      // {
      //   text: "Add Property",
      //   path: "/inventory/add-property",
      // },
    ],
    navLinks: [
      {
        text: "Home",
        icon: "mdi-home-outline",
        path: "/",
        newItemsBadge: false,
      },
      {
        text: "Event Reports",
        icon: "mdi-currency-usd",
        path: "/event-reports",
        newItemsBadge: false,
      },
      {
        text: "Users",
        icon: "mdi-account-circle-outline",
        path: "/users",
        newItemsBadge: false,
      },
      {
        text: "Messages",
        icon: "mdi-message-text-outline",
        path: "/messages",
        newItemsBadge: false,
      },
      {
        text: "Bookings/Confirmations",
        icon: "mdi-wallet-travel",
        path: "/bookings",
        newItemsBadge: false,
      },
    ],
  }),
  computed: {
    auth() {
      if (this.$store.state.isSuperAdmin) {
        router.addRoutes(this.routes);
      }
      return this.$store.state.isSuperAdmin;
    },
  },
  methods: {
    async rebuildMainSite() {
      const getData = await portalsApp
        .firestore()
        .collection("rebuildStatus")
        .doc("eshBc2QYp5PdgzzubNfN")
        .get();
      this.rebootStatus = getData.data().buildStatus == "In-progress";
      this.buildMessage = getData.data().buildStatus;
      this.rebuildDialog = true;
    },
    rebuildAgree() {
      this.loading = true;
      this.rebuild();
    },
    async rebuild() {
      if (this.rebootStatus) {
        this.$toast.error(
          "Already build is in progress ,please wait upto 3 to 4 min"
        );
        this.loading = false;
        this.rebuildDialog = false;
      } else {
        axios
          .get(`/reboot?email=adheer.sadh@sofmen.com`)
          .then((res) => {
            // console.log("res", res.data);
            this.$toast.success(res.data);
            this.loading = false;
            this.rebuildDialog = false;
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error(err.message);
            this.loading = false;
            this.rebuildDialog = false;
          });
      }
    },
  },
  watch: {
    // newItemsBadge => bookings
    bookingsConfirmations(val) {
      if (!val) return;
      if (val && !val.length) return;

      let navItem = this.navLinks.find(
        (i) => i.text == "Bookings/Confirmations"
      );

      return (navItem.newItemsBadge = true);
    },
    // newItemsBadge => messages
    userAccounts(val) {
      if (!val) return;
      if (val && !val.length) return;

      let navItem = this.navLinks.find((i) => i.text == "Messages");
      if (navItem == undefined) return;

      // extract every message => !readByAdmin
      let array = [];

      this.userAccounts.forEach((i) => {
        i.timeline.forEach((m) => {
          if (!m.readByAdmin) array.push(m);
        });
      });

      if (array.length) return (navItem.newItemsBadge = true);
      return (navItem.newItemsBadge = false);
    },
  },
};
</script>
