<template>
  <!-- unpaid eventReports -->
  <v-col cols="12" sm="6" md="4" class="d-flex">
    <v-card outlined :loading="loading">
      <v-card-text class="text--primary text-center">
        <p>
          <v-icon class="display-1">mdi-currency-usd-off</v-icon>
        </p>
        <h3 class="title">Unpaid Event Reports</h3>
        <p class="title mt-3">
          {{ unpaidEventReports ? `(${unpaidEventReports.count})` : "" }}
          {{
            unpaidEventReports
              ? `$${formatCurrency(unpaidEventReports.amount, true)}`
              : ""
          }}
        </p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import NProgress from "nprogress";
import formatCurrency from "@/mixins/formatCurrency";
export default {
  mixins: [formatCurrency],
  data: () => ({
    loading: false,
  }),
  computed: {
    items() {
      if (!this.$store.state.eventReports) return;
      return this.$store.state.eventReports.filter((i) => !i.archived);
    },
    unpaidEventReports() {
      this.items;
      if (
        !this.$store.state.user ||
        this.$store.state.user == null ||
        this.$store.state.user == undefined ||
        !this.items.length
      )
        return;

      this.loading = true;
      let array = this.items.filter((i) => !i.isPaid);
      array = array.map((i) => i.eventReportTotal);
      let amount = array.reduce((a, b) => a + b, 0);
      this.loading = false;
      return { count: array.length, amount: amount };
    },
  },
};
</script>
