<template>
  <!-- main website users last 7 days -->
  <v-col cols="12" sm="6" md="4" class="d-flex">
    <v-card outlined :loading="loading">
      <v-card-text class="text--primary text-center">
        <p>
          <v-icon color="primary" class="display-1">mdi-account-outline</v-icon>
        </p>
        <h3 class="title">Main Website Users Last 7 Days</h3>
        <p class="title mt-3">
          {{ $store.state.mainWebsiteUsersLastSevenDays }}
        </p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import axios from "axios";
import NProgress from "nprogress";
export default {
  data: () => ({
    loading: false,
  }),
  methods: {
    async getMainWebsiteUsersLastSevenDays() {
      if (this.$store.state.mainWebsiteUsersLastSevenDays != null) return;

      return new Promise(async (resolve, reject) => {
        this.loading = true;
        NProgress.start();
        try {
          let body = {
            metrics: "ga:users",
            startDate: "7daysAgo",
            endDate: "yesterday",
          };

          let request = await axios.post(
            "/api/get-google-analytics-data",
            body
          );

          console.log("7 days", request);

          // if (request.data.data && request.data.data.totalResults > 0)
          //   resolve(
          //     (this.$store.state.mainWebsiteUsersLastThirtyDays = parseInt(
          //       request.data.data.rows[0]
          //     ))
          //   );
          if (request.data.data && request.data.data.rowCount > 0)
            resolve(
              (this.$store.state.mainWebsiteUsersLastSevenDays = parseInt(
                request.data.data.rows[0].metricValues[0].value * 1
              ))
            );

          this.loading = false;
          NProgress.done();
        } catch (error) {
          console.log(error);
          this.$toast.error("Error: getMainWebsiteUsersLastSevenDays()");
          this.loading = false;
          NProgress.done();
          reject(error);
        }
      });
    },
  },
  watch: {
    "$store.state.user": {
      immediate: true,
      async handler(user) {
        if (!user || user == null || user == undefined) return;
        if (user) return await this.getMainWebsiteUsersLastSevenDays();
      },
    },
  },
};
</script>
