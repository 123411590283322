<template>
  <div>
    <v-row>
      <!-- toolbar -->
      <v-col cols="12">
        <v-toolbar dense flat dark rounded color="primary">
          <v-toolbar-title>Home</v-toolbar-title>
          <v-spacer />
          <v-toolbar-title></v-toolbar-title>
        </v-toolbar>
      </v-col>

      <SalesByMonthChart />
      <SalesBarChart />

      <!-- <SalesThisMonth />
    <SalesLast3Months />
    <SalesYtd /> -->
      <PaidEventReports />
      <UnpaidEventReports />
      <UsersToday />
      <UsersLast7Days />
      <UsersLast30Days />
      <AcSubTotal />
      <ReserveFundsCalculation />
    </v-row>
    <v-row>
      <AchFeedback />
      <LastActiveCampaignEmailSent />
      <Top10Packages />
      <ConfirmationsByMonthChart />
      <Reports />
      <!-- <PtoToday /> -->
      <ConfirmationsCalendar />
      <UpcomingEventsCalendar />
    </v-row>
  </div>
</template>

<script>
import UpcomingEventsCalendar from "@/components/UpcomingEventsCalendar";
import LastActiveCampaignEmailSent from "@/components/LastActiveCampaignEmailSent";
import SalesThisMonth from "@/components/SalesThisMonth";
import AchFeedback from "@/components/AchFeedback";
import AcSubTotal from "@/components/AcSubTotal";
import UsersLast30Days from "@/components/UsersLast30Days";
import UsersLast7Days from "@/components/UsersLast7Days";
import UsersToday from "@/components/UsersToday";
import UnpaidEventReports from "@/components/UnpaidEventReports";
import SalesLast3Months from "@/components/SalesLast3Months";
import SalesYtd from "@/components/SalesYtd";
import ConfirmationsCalendar from "@/components/ConfirmationsCalendar";
import SalesByMonthChart from "@/components/SalesByMonthChart";
import SalesBarChart from "@/components/SalesBarChart";
import Top10Packages from "@/components/Top10Packages";
import PaidEventReports from "@/components/PaidEventReports.vue";
import Reports from "@/components/Reports";
import ConfirmationsByMonthChart from "@/components/ConfirmationsByMonthChart.vue";
import ReserveFundsCalculation from "../components/ReserveFundsCalculation.vue";
// import PtoToday from "@/components/PtoToday";
export default {
  components: {
    UpcomingEventsCalendar,
    LastActiveCampaignEmailSent,
    SalesThisMonth,
    AchFeedback,
    AcSubTotal,
    UsersLast30Days,
    UsersLast7Days,
    UsersToday,
    UnpaidEventReports,
    SalesLast3Months,
    SalesYtd,
    ConfirmationsCalendar,
    SalesByMonthChart,
    SalesBarChart,
    Top10Packages,
    PaidEventReports,
    Reports,
    ConfirmationsByMonthChart,
    ReserveFundsCalculation,
    // PtoToday,
  },
};
</script>
