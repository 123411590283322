import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

// portals app
const firebasePortalsConfig = {
  apiKey: "AIzaSyAWb5JxaiaUbrcCpLn1Q4sFIfewbR4BoGk",
  authDomain: "sav-portal-dev.firebaseapp.com",
  projectId: "sav-portal-dev",
  storageBucket: "sav-portal-dev.appspot.com",
  messagingSenderId: "763700850713",
  appId: "1:763700850713:web:5c60e0c5ea7993bd2eba88",
  measurementId: "G-E7F835JP5X",
};

const portalsApp = firebase.initializeApp(firebasePortalsConfig);

// main app
const firebaseMainConfig = {
  apiKey: "AIzaSyD2BmhaqB4eLTewNqMwIyMK8lgXSWlY1-k",
  authDomain: "silent-auction-vacations-9b380.firebaseapp.com",
  projectId: "silent-auction-vacations-9b380",
  storageBucket: "silent-auction-vacations-9b380.appspot.com",
  messagingSenderId: "80462386905",
  appId: "1:80462386905:web:a369479a8e46981bb0d0c8",
  measurementId: "G-WCWEZWCJ4L",
};

const mainApp = firebase.initializeApp(firebaseMainConfig, "secondary");

export { mainApp, portalsApp };
