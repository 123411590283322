import { portalsApp } from "@/firebase";

export default {
  beforeMount() {
    portalsApp.auth().onAuthStateChanged((user) => {
      console.log("authstate", user);
      // invalid user
      if (user == null || user == undefined)
        return (this.$store.state.authDialog = true);

      // user not admin
      // "rxIsdN0OgrcTIetS2VEQZsDqahJ3"
      if (user && user.uid != "Uds7cHTgXMZC8pxXEifBq7E5UtW2")
        return this.signOut();

      // user valid => set user in store
      return (this.$store.state.user = user);
    });
  },
};
